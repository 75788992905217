.home {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 50px;
}
.cake{
  max-width: 900px;
  margin: 40px auto;
}
.tl{
  max-width: 900px;
  margin: 50px auto;
}

.app-icon {
  padding: 10px;
}

.card-deck{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
}

.cardextra{
  margin: 10px;
}

.tdXRPTable{
  text-align: center;
  vertical-align: middle;
}